import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './Popup.css'
import Filter from './Filter';

const customIcon = L.icon({
    iconUrl: require('../icons/1x/home-3.png'),
    iconSize: [41, 41], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34] // Point from which the popup should open relative to the iconAnchor
  });

const Map = () => {
  return (
    <div style={{ position: 'relative' }}>
      <Filter />
      <MapContainer 
        center={[37.778691, -122.524626]} 
        zoom={12} 
        style={{ height: '100vh', width: '100%' }}
        zoomControl={false}
      >
        <TileLayer
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[37.750247, -122.459481]} icon={customIcon}>
          <Popup className='home-popup'>
            <div>
              <a href="/homes/sonida-health">
                <img src={require('../img/carenurse.jpg')} alt="Location" style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="/homes/sonida-health">
                <h2>Sonida Care Home</h2>
              </a>
              <p>Beautiful Memory Care Facility with 8 private rooms.</p>
            </div> 
          </Popup>
        </Marker>
        <Marker position={[37.784294, -122.453373]} icon={customIcon}>
          <Popup className='home-popup'>
            <div>
              <a href="/homes/sonida-health">
                <img src={require('../img/carenurse.jpg')} alt="Location" style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="/homes/sonida-health">
                <h2>Sonida Care Home</h2>
              </a>
              <p>Beautiful Memory Care Facility with 8 private rooms.</p>
            </div> 
          </Popup>
        </Marker>
        <Marker position={[37.788904, -122.418034]} icon={customIcon}>
          <Popup className='home-popup'>
            <div>
              <a href="/homes/sonida-health">
                <img src={require('../img/carenurse.jpg')} alt="Location" style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="/homes/sonida-health">
                <h2>Sonida Care Home</h2>
              </a>
              <p>Beautiful Memory Care Facility with 8 private rooms.</p>
            </div> 
          </Popup>
        </Marker>
        <Marker position={[37.761141, -122.410740]} icon={customIcon}>
          <Popup className='home-popup'>
            <div>
              <a href="/homes/sonida-health">
                <img src={require('../img/carenurse.jpg')} alt="Location" style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="/homes/sonida-health">
                <h2>Sonida Care Home</h2>
              </a>
              <p>Beautiful Memory Care Facility with 8 private rooms.</p>
            </div> 
          </Popup>
        </Marker>
        <Marker position={[37.734540, -122.416857]} icon={customIcon}>
          <Popup className='home-popup'>
            <div>
              <a href="/homes/sonida-health">
                <img src={require('../img/homes/nursinghome.webp')} alt="Location" style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="/homes/sonida-health">
                <h2>Sonida Memory Care</h2>
              </a>
              <p>Beautiful Memory Care Facility with 8 private rooms. Designed for accessibility and patient independence. </p>
            </div> 
          </Popup>
        </Marker>
        <Marker position={[37.726934, -122.450917]} icon={customIcon}>
          <Popup className='home-popup'>
            <div>
              <a href="/homes/sonida-health">
                <img src={require('../img/carenurse.jpg')} alt="Location" style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="/homes/sonida-health">
                <h2>Sonida Care Home</h2>
              </a>
              <p>Beautiful Memory Care Facility with 8 private rooms.</p>
            </div> 
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
