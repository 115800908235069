import React from 'react';
import './Filter.css';

const homesData = [
  {
    id: 1,
    title: 'Sonida Memory Care',
    description: 'Specialized care for individuals with memory loss.',
    tags: ['Memory', 'RCFE'],
    image: require('../img/homes/happy.jpeg')
  },
  {
    id: 2,
    title: 'Seaview Retirement Center',
    description: 'Comprehensive care with a focus on hospice and disability services.',
    tags: ['Hospice', 'Disability', 'RCFE'],
    image: require('../img/homes/nursinghome.webp')
  },
  {
    id: 3,
    title: 'Alta Bella Nursing Home',
    description: 'Culturally focused care for Spanish-speaking residents.',
    tags: ['Spanish', 'Disability', 'RCFE'],
    image: require('../img/homes/paint.webp')
  },
  {
    id: 4,
    title: 'Pohaima Retirement Village',
    description: 'Caring community with ESL and medication management programs.',
    tags: ['ESL', 'Medication'],
    image: require('../img/homes/pohaima.jpg')
  },
  {
    id: 5,
    title: 'South Palma Long Term Care',
    description: 'Focused on residents with hearing loss and long-term care needs.',
    tags: ['Hearing Loss', 'Group Home'],
    image: require('../img/homes/south.jpeg')
  }
];

const Filter = () => {
  return (
    <div className="Filter">
      <input type="text" placeholder="Filters" className="SearchBar" />
      <div className="HomesList">
        {homesData.map(home => (
          <div key={home.id} className="HomeCard">
            <img src={home.image} alt={home.title} className="HomeImage" />
            <h3>{home.title}</h3>
            <div className="tags">
              {home.tags.map(tag => <span className="tag">{tag}</span>)}
            </div>
            <p>{home.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filter;