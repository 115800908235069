import React from 'react';
import './Hero.css'; // Import the CSS for styling
import image from '../img/resedential-care.jpg';

const Hero = () => {
  return (
    <div className="Hero">
      <div className="HeroText">
        <p>Patching together</p>
        <p>The network of discharge care.</p>
        <a href="https://www.example.com" className="ctaButton">Start Finding Homes</a>
      </div>
      <div className="HeroImage">
        <img src={image} alt="Residential Care" />
      </div>
    </div>
  );
};

export default Hero;