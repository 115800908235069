import React from 'react';
import './Centers.css'; // Import the CSS for styling
import image1 from '../img/homes/happy.jpeg';
import image2 from '../img/homes/nursinghome.webp';
import image3 from '../img/homes/paint.webp';
import image4 from '../img/homes/pohaima.jpg';
// import image5 from '../img/homes/south.jpeg';



const centersData = [
  {
    id: 1,
    title: 'Newport Institute Northern California',
    description: 'Locations across Northern California treat young adults ages 18-35 using integrated care, healing substance use disorders...',
    image: image1
  },
  {
    id: 2,
    title: 'Newport Academy Northern California',
    description: 'In Sacramento and the San Francisco Bay, teens ages 12-18 live in gender-specific homes as they receive treatment for...',
    image: image2
  },
  {
    id: 3,
    title: 'Bayside Marin',
    description: 'With remarkably qualified staff and deluxe accommodations, Bayside Marin offers a blend of holistic and traditional therapies...',
    image: image3
  },
  {
    id: 4,
    title: 'Serenity Knolls',
    description: 'Surrounded by hills and trees, Serenity Knolls is a private campus with cabins, 12-Step treatment...',
    image: image4
  }
];

const Centers = () => {
  return (
    <div className="Centers">
      <h2>Homes Near You</h2>
      <div className="CentersList">
        {centersData.map(center => (
          <div key={center.id} className="CenterCard">
            <img src={center.image} alt={center.title} className="CenterImage" />
            <div className="CenterInfo">
              <h3>{center.title}</h3>
              <p>{center.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Centers;
