import React from 'react';
import './Sonida.css';
import image1 from '../img/homes/sonida/image1.png';
import image2 from '../img/homes/sonida/image2.png';
import image3 from '../img/homes/sonida/image3.png';
import image4 from '../img/homes/sonida/image4.png';
import image5 from '../img/homes/sonida/image5.png';

const Sonida = () => {
  return (
    <div className="sonida-container">
      <div className="image-scroll-container">
        <img src={image1} alt="Description1" />
        <img src={image2} alt="Description2" />
        <img src={image3} alt="Description3" />
        <img src={image4} alt="Description4" />
        <img src={image5} alt="Description5" />
      </div>
      <div className="content-wrapper">
        <div className="left-section">
          <h1 className="title">Sonida Memory Care Facility</h1>
          <div className="tags">
            <span className="tag">Medication Management</span>
            <span className="tag">Memory Care</span>
            <span className="tag">RCFE</span>
            <span className="tag">Spanish</span>
            <span className="tag">ESL</span>
          </div>
          <p className="description">
            Sonida Memory Care provides a nurturing environment tailored to the needs of individuals with memory impairment. Our staff is trained in the latest memory care techniques, ensuring that each resident receives compassionate and appropriate care. We offer a variety of activities designed to enrich the lives of our residents and promote cognitive engagement.
          </p>
          <div className="contact">
            <button className="cta-button">Request a Tour</button>
            <button className="secondary-button">View Contact Information</button>
          </div>
        </div>
        <div className="right-section">
          <div className="activities">
            <h2>Weekly Agenda</h2>
            <ul>
              <li className="activity">Monday: Painting</li>
              <li className="activity">Tuesday: Scrabble</li>
              <li className="activity">Wednesday: Breakfast at Restaurant</li>
              <li className="activity">Thursday: Weekly Visits</li>
              <li className="activity">Friday: Movie Night</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sonida;
