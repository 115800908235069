import React from 'react';
import './Homes.css';

const homesData = [
  {
    id: 1,
    title: 'Memory Care',
    description: 'Specialized care for individuals with memory loss.',
    link: '/memory-care',
    emoji: '🧠'
  },
  {
    id: 2,
    title: 'RCFE',
    description: 'Residential Care Facilities for the Elderly.',
    link: '/rcfe',
    emoji: '👵'
  },
  {
    id: 3,
    title: 'Adult Care',
    description: 'Care services for adults with disabilities.',
    link: '/adult-care',
    emoji: '🧑‍🦽'
  },
  {
    id: 4,
    title: 'Assisted Living',
    description: 'Supportive services for daily living activities.',
    link: '/assisted-living',
    emoji: '🏡'
  }
];

const Homes = () => {
  return (
    <div className="homes-container">
      <h1 className="title">Find Homes</h1>
      <p className="subtitle">Browse different types of care homes</p>
      <div className="homes-list">
        {homesData.map(home => (
          <div key={home.id} className="home-card">
            <h2>{home.emoji} {home.title}</h2>
            <p>{home.description}</p>
            <a href={home.link}>Learn More</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Homes;
