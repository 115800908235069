import Header from './components/Header';
import Hero from './components/Hero'; // Import the Hero component
import Centers from './components/Centers'; // Import the Centers component
import Homes from './components/Homes'; // Import the Homes component
import { Routes, Route } from 'react-router-dom';
import Map from './components/Map';
import About from './components/About'; // Import the About component
import Sonida from './components/Sonida';


function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <div className="MainContent">
              <Centers />
              <Homes />
            </div>
          </>
        } />
        <Route path="/map" element={<Map />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/homes/sonida-health" element={<Sonida />} />

      </Routes>
    </div>
  );
}

export default App;