import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Make sure to import the CSS for styling

const Header = () => {
  return (
    <header className="Header">
      <Link to="/" className="HeaderTitle">qilt</Link>
      <nav>
      <Link to="/about">Why qilt</Link>
        <Link to="/map">Locations</Link>
      </nav>
    </header>
  );
};

export default Header;